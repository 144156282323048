export default defineNuxtPlugin(() => {

  HTMLElement.prototype.getInnerHeight = function () {
    const computed = getComputedStyle(this);
    const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
    return this.clientHeight - padding;
  }

  HTMLElement.prototype.getInnerWidth = function () {
    const computed = getComputedStyle(this);
    const padding = parseInt(computed.paddingLeft) + parseInt(computed.paddingRight);
    return this.clientWidth - padding;
  }

  HTMLElement.prototype.findScrollContainer = function () {
    let parent = this.parentElement;
    while (parent) {
      const {overflow, overflowX, overflowY} = window.getComputedStyle(parent);
      if (overflow.split(' ').every(o => o === 'auto' || o === 'scroll')) {
        return parent;
      }
      if (overflowX.split(' ').every(o => o === 'auto' || o === 'scroll')) {
        return parent;
      }
      if (overflowY.split(' ').every(o => o === 'auto' || o === 'scroll')) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return document.documentElement;
  }

})
