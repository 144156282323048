import { useEnvironment } from "~/composables/Environment";

export const useConsole = () => {

  const {isProduction} = useEnvironment()

  const log = (...params) => {
    if (isProduction) return;
    console.log(...params);
  }

  const trace = () => {
    if (isProduction) return;
    console.trace();
  }

  const publog = (...params) => {
    console.log(...params);
  }

  const error = (...params) => {
    if (isProduction) return;
    console.error(...params)
  }

  const warn = (...params) => {
    if (isProduction) return;
    console.warn(...params)
  }

  return {
    log,
    trace,
    publog,
    error,
    warn
  }
}
