export default defineNuxtPlugin({
  hooks: {
    'app:created': () => {
      const backend = useBackend();
      return backend.getSetup();
    },
    'page:finish': () => {
      const route = useRoute();
      const app = useNuxtApp();
      switch (route.name) {
        case "brand":
          const topElem = document.getElementById('top');
          if (topElem && app.$isMobile()) {
            const {top} = topElem.getBoundingClientRect();
            setTimeout(() =>
                window.scrollTo({top: top - 90, behavior: 'smooth'}),
              250
            );
          } else {
            window.scrollTo(0, 0);
          }
          break;
        default:
          window.scrollTo(0, 0);
      }

      const {analyticsLayer} = useAnalytics();
      analyticsLayer({
        'event': 'virtualPageview',
        'pageUrl': window.location.href,
        'pageReferrer': 'https://vehis.pl/',
        'pageTitle': document.title
      })

    },
    'page:start': () => {
      const globals = useGlobals();

      globals.resetGlobals();

      const leasing = useLeasingParams();
      leasing.leasingDrawerOpened.value = false;

      const app = document.getElementById("__nuxt");
      app.style.transition = "none";
      app.style.marginTop = "0";
    }
  }
})
