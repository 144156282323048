export const useAPI = () => {

  const config = useRuntimeConfig();
  const globalStore = typeof useState !== "undefined" ? useState('globalState', () => ({})) : {value:{}};
  const console = typeof useConsole !== "undefined" ? useConsole() : null;

  const reqStore = typeof useState !== "undefined" ? useState('reqState',() => []) : {value: []}
  const log = (...args) => {
    if(console) {
      reqStore.value.push(...args);
      console.log(`🚀 RQST ${reqStore.value.length}:`, ...args);
    }
  }

  const logResp = (...args) => {
    if(console) console.log("👂 RESP:", ...args);
  }

  const fetch = async (endpoint, opts = {}) => {
    opts.query = opts.query || {};

    const reqStart = (new Date()).getTime();
    let resultResponse = {}

    opts.headers = opts.headers || {}
    opts.headers.Accept = "application/json";

    opts = Object.assign({
      baseURL: config.public.apiEndpoint,
      ignoreResponseError: true,
      parseResponse: JSON.parse,

      onResponse({request, options, response}) {
        resultResponse = response;
        logResp( endpoint, ((new Date()).getTime() - reqStart), "ms");
      }
    }, opts)

    const resultData = await $fetch(endpoint, opts);

    return {
      data: resultData,
      status: resultResponse.status
    }
  }

  const login = () => {
    return new Promise((success, failure) => {
      if (globalStore.value.api_token) {
        return success(globalStore.value.api_token);
      }

      fetch(
        "/login",
        {
          body: {
            'login': config.public.apiUser,
            'password': config.public.apiPassword
          },
          method: 'POST'
        }
      ).then(response => {
          const token = response.data.data.token;
          globalStore.value.api_token = token;
          success(token);
        }
      ).catch(fail => failure(fail))
    });
  }

  const request = (endpoint, params, method) => {

    if (process.env.NODE_ENV === 'development') {
      endpoint += (endpoint.indexOf("?") !== -1 ? "&" : "?") + "bypass=1";
    }

    log(`${method || 'POST'} request to ${endpoint}`, params || []);


    return new Promise((success, failure) => {
      login().then(
        (token) => {
          fetch(endpoint, {
            method: method || 'GET',
            body: params || null,
            headers: {
              Authorization: "Bearer " + token
            }
          }).then(response => {
            success(response)
          }).catch(errorResponse => {
            const response = {
              error: true,
              response: errorResponse.response ? errorResponse.response._data : []
            }
            success({data: response});
          });
        }
      )
    })
  }

  return {
    request,
    login
  }
}
