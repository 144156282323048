const maskUtils = {
  applyMask(value, mask, displayPattern) {
    if (!mask || !displayPattern) return value;
    let valueIndex = 0;
    const cleanedValue = value.replace(/\D/g, '');
    let maskedValue = '';

    for (let patternIndex = 0; patternIndex < displayPattern.length; patternIndex++) {
      if (mask[patternIndex] === '#') {
        maskedValue += cleanedValue[valueIndex] !== undefined ? cleanedValue[valueIndex++] : displayPattern[patternIndex];
      } else {
        maskedValue += displayPattern[patternIndex];
      }
    }

    return maskedValue;
  },

  calculateCursorPosition(start, oldMaskedValue, newMaskedValue, mask) {
    let cursorPos = start;
    let skipChars = 0;

    for (let i = 0; i < start; i++) {
      if (mask[i] && mask[i] !== '#' && oldMaskedValue[i] !== newMaskedValue[i]) {
        skipChars++;
      }
    }

    cursorPos += skipChars;

    while (cursorPos < newMaskedValue.length && mask[cursorPos] && mask[cursorPos] !== '#') {
      cursorPos++;
    }

    return cursorPos;
  },

  handleBackspaceOrDelete(event, element, mask, displayPattern, updateMasking) {
    const start = element.selectionStart;
    const end = element.selectionEnd;

    if (start === end) {
      const valueArray = element.value.split('');
      const offset = event.key === 'Backspace' ? -1 : 0;
      let found = false;

      for (let i = start + offset; i >= 0 && i < valueArray.length; i += (event.key === 'Backspace' ? -1 : 1)) {
        if (mask[i] === '#') {
          valueArray[i] = displayPattern[i];
          found = true;
          break;
        }
      }

      if (found) {
        element.value = valueArray.join('');
        event.preventDefault();
        updateMasking();
        element.setSelectionRange(start + offset, start + offset);
      }
    }
  },

  registerEvents(element, handlers) {
    element.addEventListener('input', handlers.updateMasking);
    element.addEventListener('focus', handlers.handleFocus);
    element.addEventListener('blur', handlers.handleBlur);
    element.addEventListener('keydown', handlers.handleKeydown);
  },

  unregisterEvents(element, handlers) {
    element.removeEventListener('input', handlers.updateMasking);
    element.removeEventListener('focus', handlers.handleFocus);
    element.removeEventListener('blur', handlers.handleBlur);
    element.removeEventListener('keydown', handlers.handleKeydown);
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('mask', {
    mounted(element, binding) {
      const [mask, displayPattern] = binding.value;
      element.__masking = false;
      element.__initialized = false;

      const updateMasking = () => {
        if (element.__masking) return;
        element.__masking = true;

        const originalValue = element.value.replace(/_/g, '').replace(/[^0-9]/g, '');
        const oldMaskedValue = element.value;
        const newValue = maskUtils.applyMask(originalValue, mask, displayPattern);

        const cursorPosition = maskUtils.calculateCursorPosition(element.selectionStart, oldMaskedValue, newValue, mask);
        element.value = newValue;

        element.dispatchEvent(new Event('input', { bubbles: true }));
        element.setSelectionRange(cursorPosition, cursorPosition);

        element.__masking = false;
      };

      const handleFocus = () => {
        setTimeout(() => {
          if (!element.__initialized || element.value === '') {
            element.__initialized = true;
            element.value = maskUtils.applyMask('', mask, displayPattern);
            element.setSelectionRange(0, 0);
          }
        }, 0);
      };

      const handleBlur = () => {
        if (element.value === maskUtils.applyMask('', mask, displayPattern)) {
          element.value = '';
          element.__initialized = false;
        }
      };

      const handleKeydown = (event) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          maskUtils.handleBackspaceOrDelete(event, element, mask, displayPattern, updateMasking);
        }
      };

      const handlers = { updateMasking, handleFocus, handleBlur, handleKeydown };
      maskUtils.registerEvents(element, handlers);

      if (element.value) {
        updateMasking();
      }
    },

    updated(element, binding) {
      const [mask, displayPattern] = binding.value;
      if (!mask || !displayPattern) return;

      const originalValue = element.value.replace(/_/g, '').replace(/[^0-9]/g, '');
      const oldMaskedValue = element.value;
      const newValue = maskUtils.applyMask(originalValue, mask, displayPattern);
      const cursorPosition = maskUtils.calculateCursorPosition(element.selectionStart, oldMaskedValue, newValue, mask);
      element.value = newValue;
      element.setSelectionRange(cursorPosition, cursorPosition);
    },

    unmounted(element) {
      const handlers = { updateMasking, handleFocus, handleBlur, handleKeydown };
      maskUtils.unregisterEvents(element, handlers);
    }
  });
});
