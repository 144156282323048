export default defineNuxtPlugin( {
  hooks: {
    async 'app:mounted'() {

      const checkVersion = async () => {
        const response = await $fetch("/srv/ver", {
          ignoreResponseError: true,
          parseResponse: JSON.parse
        });

        const currentAppVersion = response.ver ? response.ver + "" : null;
        if (!currentAppVersion) return; // cannot check version, so do nothing
        const appVersion = localStorage.getItem('app_version');

        if (!appVersion) {
          localStorage.setItem('app_version', currentAppVersion);
          return;
        }

        if (appVersion !== currentAppVersion) {
          localStorage.setItem('app_version', currentAppVersion);
          reloadNuxtApp();
        }
      }
      await checkVersion();
      setInterval(checkVersion, 15000);
    }
  }
})
