import mitt from "mitt";

export default defineNuxtPlugin(() => {

  const emitter = mitt();

  return {
    provide: {
      triggerEvent: emitter.emit,
      onEvent: emitter.on
    }
  }
})
