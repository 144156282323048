import { useNuxtApp } from "#app";

export const useGlobals = () => {

    if(process.client) {
      document.appGlobals = document.appGlobals || {}
    }
    const setGlobal = (name, value) => {
      if(process.server) return;
      document.appGlobals[name] = value
    }

    const getGlobal = (name, defaultValue) => {
      if(process.server) return defaultValue;
      return document.appGlobals[name] || defaultValue;
    }

    const resetGlobals = () => {
      if(process.client) {
        document.appGlobals = {}
      }
    }

    return {
      getGlobal,
      setGlobal,
      resetGlobals
    }
}
