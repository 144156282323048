import { useBadRoutes } from "~/composables/BadRoutes";
import { useNuxtApp } from "#app";

export default defineNuxtPlugin((nuxtApp) => {

  const console = useConsole();

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error('🟥 ERROR!', info, error);
  }

  nuxtApp.hook('vue:error', async (error, instance, info) => {

  })
})
