<script setup>
const utm = useUtm();
utm.setUtm();
</script>

<template>
  <NuxtLoadingIndicator color="#0A66A8"/>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
