export default defineNuxtPlugin( {

    hooks: {
        'app:mounted'() {
            let handle = setInterval(() => {
                const console = useConsole();

                if(typeof window['LeadBooster'] === "undefined" ) {
                    return;
                }

                if(typeof window.LeadBooster.iframe === "undefined") {
                    return;
                }

                clearInterval(handle);

                window.LeadBooster.iframe.document.body.addEventListener('click', (e) => {
                    if (e.target.matches('button[data-testid="chat-option-0"]') || e.target.matches('button[data-testid="chat-option-0"] span')) {
                        useBackend().teamsNotify();
                    }
                });

            }, 1000);
        }
    }
})