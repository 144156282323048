export const useSession = () => {

  const items = useState('Session',() => ({}))
  let intervalHandle = null;

  const update = () => {
    if (!process.client) return;
    let storageData = {};
    Object.keys(sessionStorage).forEach(key => {
      try {
        storageData[key] = JSON.parse(sessionStorage.getItem(key));
      } catch (e) {
        storageData[key] = sessionStorage.getItem(key);
      }
    });
    items.value = storageData;
  }

  const setItem = (key, value) => new Promise(async done => {
    if (!process.client) return;
    items.value[key] = value;
    if(typeof value === "object") value = JSON.stringify(value);
    sessionStorage.setItem(key, value);
    done();
  });

  const getItem = (key) => {
    update()
    const keyValue = items.value[key]
    return keyValue || null;
  }

  onMounted(() => {
    update();
  })

  onUnmounted(() => {
    clearInterval(intervalHandle);
  })

  update();

  return {
    setItem,
    getItem,
    items
  }
}
