export const useBackend = () => {

  const console = useConsole();
  const api = useAPI();
  const prices = useState('storedPrices');
  const backendStore = useBackendStore();

  const getCars = (params, priceSortOrder = 1) => {
    let query = new URLSearchParams(params);
    return new Promise(success => {
      api.request(
        "/v3/cars/?" + query.toString()
      ).then(result => {
        result.data.sort((a, b) => (a.price - b.price) * priceSortOrder)
        success(result);
      });
    })
  }

  const getCar = (id, fullEquipment = true, showPrice = false) => {
    mockApiCall('getCar');

    return api.request(
      "/v3/car/?" + (fullEquipment ? "fullEq=1&" : "") + (showPrice ? "showPrice=1&" : "") + "groupId=" + id
    )
  }

  const getModelBodyVariants = (params) => {
    return new Promise((success) => {
      getCars(params).then(results => {
        const variantsResult = groupCarsIntoVariants(results.data);
        success({data: variantsResult});
      });
    });
  }

  const groupCarsIntoVariants = (carsResult) => {
    let carVariants = [];
    let variantsResult = [];
    carsResult.forEach(variant => {

      let carVariantIndex = carVariants.indexOf(variant.bodyType);

      if (carVariantIndex === -1) {
        carVariants.push(variant.bodyType);
        carVariantIndex = carVariants.indexOf(variant.bodyType);
      }

      let labels = [];

      if (variant.count) {
        if (variantsResult[carVariantIndex]) {
          if (variantsResult[carVariantIndex].price === 0) {
            variantsResult[carVariantIndex] = Object.assign({}, variant);
          } else {
            if (variantsResult[carVariantIndex].price > variant.price) {
              variantsResult[carVariantIndex] = Object.assign({}, variant);
            } else {
              variantsResult[carVariantIndex].count += variant.count;
            }
          }
          let labels = variantsResult[carVariantIndex].labels.concat(variant.labels);
          let codes = labels.map(({code}) => code);
          labels = labels.filter(({code}, i) => !codes.includes(code, i + 1));
          variantsResult[carVariantIndex].labels = labels;
        } else {
          variantsResult[carVariantIndex] = Object.assign({}, variant);
        }
      } else {
        if (!variantsResult[carVariantIndex]) {
          variant.price = 0;
          variantsResult[carVariantIndex] = Object.assign({}, variant);
        }
      }
    });

    console.log(variantsResult);

    return variantsResult;
  }

  const getCarsByRoute = (route) => {
    mockApiCall('getCarsByRoute', route.name);
    let params = {}
    switch (route.name) {
      case 'index':
        return getModels({promoted: 1});
      case 'brand':
        return getModels({
          brand: route.params.brand,
        });
      case 'model':
        return getModelBodyVariants({
          brandName: route.params.brand,
          modelName: route.params.model
        })
      case 'body':
        return getModels({
          bodyType: route.params.body,
        });
      case 'bodyBrands':
        return getModels({
          bodyType: route.params.body,
          brand: route.params.brands
        });
      case 'purpose':
        return getModels({
          purposeType: route.params.purpose
        });
      case 'purposeBrands':
        return getModels({
          purposeType: route.params.purpose,
          brand: route.params.brands
        });
      // return getCarsBodyTypes({
      //     purposeType: route.params.purpose,
      //     brandName: route.params.brands
      // });

    }
  }

  const getCarsBodyTypes = (filters) => {
    return new Promise(success => {
      getCars(filters).then(results => {
        let carVariants = [];
        let variantsResult = [];
        results.data.forEach(variant => {
          if (carVariants.indexOf(variant.bodyType) === -1) {
            carVariants.push(variant.bodyType);
            variantsResult.push(variant);
          }
        });
        success({data: variantsResult});
      });
    })
  }

  const getModels = (params) => {
    mockApiCall('getModels');
    return api.request(
      "/v3/models/?" + prepareRequestQuery(params)
    );
  }

  const search = (params) => {
    mockApiCall('search');
    return api.request(
      "/v3/search/?" + prepareRequestQuery(params)
    );
  }

  const getBrands = () => {
    return new Promise(success => {

      mockApiCall('getBrands');
      const fromStore = getFromStore('brands');
      if (fromStore.data) return success(fromStore);

      api.request("/v3/brands")
        .then(result => {
          sortResults(result.data, 'name');
          success(result);
        });
    })
  }

  const getSetup = () => {
    mockApiCall('getSetup');
    const backendStore = useBackendStore();

    return new Promise(success => {
      const fromStore = getFromStore('setup');
      if (fromStore.data) return success(fromStore);

      api.request("/v3/setup")
        .then(result => {

          if (result.data.brands) {

            sortResults(result.data.brands, 'name');
            backendStore.setBrands({data: result.data.brands});
            // backendStore.setPurposes({data: result.data.purposes});
            backendStore.setBodies({data: result.data.bodytypes});
            backendStore.setSetup({data: result.data});
            backendStore.setCarCount(result.data.count);

            prices.value = {
              min: result.data.prices.min,
              max: result.data.prices.max
            }
          }
          success(result);
        });
    })
  }

  const getBodies = () => {
    mockApiCall('getBodies');
    return new Promise(success => {
      const fromStore = getFromStore('bodies');
      if (fromStore.data) return success(fromStore);

      api.request("/v3/bodytypes").then(result => success(result));
    });
  }

  const getGearBoxes = () => {
    mockApiCall('getGearboxes');
    return new Promise(success => {
      const fromStore = getFromStore('gearboxes');
      if (fromStore.data) return success(fromStore);

      api.request("/v3/gearboxtypes").then(result => success(result));
    });
  }

  const getDrives = () => {
    mockApiCall('getDrives');
    return new Promise(success => {
      const fromStore = getFromStore('drives');
      if (fromStore.data) return success(fromStore);

      api.request("/v3/drivetypes").then(result => success(result));
    });
  }

  const getPurposes = () => {
    mockApiCall('getPurposes');
    return new Promise(success => {
      const fromStore = getFromStore('purposes');
      if (fromStore.data) return success(fromStore);

      api.request("/purposes").then(result => success(result));
    })
  }

  const getEquipmentDictionary = () => {
    mockApiCall('getEquipmentDictionary');
    return api.request(
      "/equipment-dictionary/"
    );
  }

  const teamsNotify = () => {
    return api.request(
      "/notify/", {}, 'post'
    );
  }

  const getDownloadLinkForFile = (name) => {
    mockApiCall('getDownloadLinkForFile');

    const files = {
      rodo: "/files/docs/informacja_administrtora_danych_osobowych_formularz_kontaktowy.pdf",
      services: "/files/docs/partnerzy_serwisowi.pdf",
      bik: "/files/docs/zgoda_bik.pdf",
      privacy: "/files/docs/polityka_prywatnosci.pdf",
      fbcontest: "/files/docs/fb-contest-rules.pdf",
      blackfriday: "/files/docs/BF_Regulamin_promocji_cashback.pdf",
      cybermonday: "/files/docs/CM_Regulamin_promocji_cashback.pdf"
    };

    return files[name] || "/";
  }

  const sendContactForm = (data) => {
    mockApiCall('sendContactForm');
    if (!data.site) {
      data.site = 'vehis.pl';
    }
    return api.request('/contact-form', data, 'post')
  }

  const sendDealerContactForm = (data) => {
    mockApiCall('sendDealerContactForm');
      return $fetch('/srv/notion', {
        method: 'POST',
        body: data,
      });
  }

  const getCommunicates = () => {
    mockApiCall('getCommunicates');
    return new Promise(success => {
      api.request("/communicates")
        .then(result => {
          result = groupCommunicates(result.data);
          success(result);
        });
    })
  }

  const getModelSeo = (id) => {
    mockApiCall('getModelSeo');
    return api.request("/seo/model?id=" + id);
  }

  const getBrandSeo = (brandName) => {
    mockApiCall('getBrandSeo');
    return api.request("/seo/brand?brand=" + brandName);
  }

  const getLimitedOffer = (modelId, bodyType) => {
    mockApiCall('getLimitedOffer');
    return api.request("/limited-offer?modelId=" + modelId + "&bodyType=" + bodyType);
  }

  const getLanding = (slug, preview = false) => {
    mockApiCall('getLanding');
    return api.request("/landing?slug=" + slug + (preview ? "&preview=1" : ''));
  }

  const getActualLanding = () => {
    mockApiCall('getActualLanding');
    return api.request("/actual-landing");
  }

  const getRedirectLanding = (brandName = null, modelName = null, bodyType = null, preview = false) => {
    mockApiCall('getActualLanding');
    return api.request("/actual-landing-redirect?brand=" + (brandName || "") + "&model=" + (modelName || "") + "&bodyType=" + (bodyType || "") + "&preview=" + (preview ? "1" : "0"));
  }

  const getBanners = (brandName) => {
    mockApiCall('getBanners');
    return api.request("/banners?brandName=" + (brandName || ""));
  }

  const getBannerBrands = () => {
    mockApiCall('getBannersCounts');
    return api.request("/banner-brands");
  }

  const digitalOrderStoreContact = (params) => {
    mockApiCall('digitalOrderStoreContact');
    return new Promise(success => {
      api.request(
        "/digital-order/storeContact",
        params,
        'post'
      ).then(result => {
        if (typeof result.data !== "undefined") {
          success(result.data);
        } else {
          success(null);
        }
      });
    })
  }

  const createDigitalOrder = (params) => {
    return new Promise(success => {
      api.request(
        "/digital-order/create",
        params,
        'post'
      ).then(result => {
        if (typeof result.data !== "undefined") {
          success(result.data);
        } else {
          success(null);
        }
      });
    })
  }

  const leasingParamDigitalOrder = (params) => {
    return new Promise(success => {
      api.request(
        "/digital-order/storeLeasingParam",
        params,
        'post'
      ).then(result => {
        if (typeof result.data !== "undefined") {
          success(result.data);
        } else {
          success(null);
        }
      });
    })
  }

  const storeDigitalOrderData = (params) => {

    mockApiCall('storeDigitalOrderData', params);

    return new Promise(success => {
      api.request(
        "/digital-order/storeOrderData",
        params,
        'post'
      ).then(result => {
        if (typeof result.data !== "undefined") {
          success(result.data);
        } else {
          success(null);
        }
      });
    })
  }

  const getDigitalOrder = (uuid, secret) => {
    mockApiCall('getDigitalOrder');
    return api.request(
      "/digital-order/get?" + "uuid=" + uuid + "&secret=" + secret
    )
  }

  const getCompanyData = (nip) => {
    mockApiCall('getCompanyData');
    return api.request(
      "/digital-order/getCompanyData?" + "nip=" + nip
    )
  }

  const getBankList = () => {
    mockApiCall('getBankList');
    return api.request(
      "/digital-order/psd2/bank-list"
    )
  }
  const initiatePsd2 = (data) => {
    mockApiCall('initiatePsd2');
    return api.request('/digital-order/psd2/initiate/', data, 'post')
  }

  const initiatePsd2Result = (data) => {
    mockApiCall('initiatePsd2Result');
    return api.request('/digital-order/psd2/result', data, 'post')
  };

  const checkDigitalOrderStatus = (uuid) => {
    mockApiCall('checkDigitalOrderStatus');
    return api.request(
      "/digital-order/checkStatus?" + "uuid=" + uuid
    )
  }
  const storeShowOffer = (uuid) => {
    mockApiCall('storeShowOffer');
    return api.request(
      "/digital-order/storeShowOffer?" + "uuid=" + uuid
    )
  }
  const getDigitalOrderSetup = () => {
    mockApiCall('getDigitalOrderSetup');
    return api.request(
      "/digital-order/setup"
    )
  }

  const getModelBodyTypes = (brand, model, all = null) => {
    return api.request(
      `v3/model-bodytypes?model=${model}&brand=${brand}` + (all ? "&all=true" : "")
    );
  }

  const getBrandsET = () => {
    return api.request(
      `/digital-order/eurotax/brands`
    );
  }

  const getModelsET = (brandId) => {
    return api.request(
      `/digital-order/eurotax/models?id=${brandId}`
    );
  }

  const getVersionsET = (modelId) => {
    return api.request(
      `/digital-order/eurotax/versions?id=${modelId}`
    );
  }

  const getDocument = (slug) => {
    mockApiCall('getDocument', slug);
    return api.request(`/document?slug=${slug}`)
  }

  const getFooterLinks = () => {
    mockApiCall('getFooterLinks');
    return api.request('/footer-links');
  }

  const getModelDescription = (model) => {
    mockApiCall('getModelDescription');
    model = toLowerDashed(model);
    return api.request(`/v2/model-description/${model}?bypass=1`);
    // todo: remove enforced bypass here
  }

  const getModelDescriptionCards = () => {
    mockApiCall('getModelDescriptionCards');
    return api.request(`/v2/model-description-cards?bypass=1`);
    // todo: remove enforced bypass here
  }

  const getCollections = () => {
    mockApiCall('getCollections');
    return api.request(`/v2/collections/`);
  }

  const getCollection = (slug) => {
    mockApiCall('getCollection', slug);
    return api.request(`/v2/collection?slug=${slug}`);
  }
  const getCarLiczakOffer = (slug) => {
    mockApiCall('getCarLiczakOffer', slug);
    return  api.request(`/offer?uuid=${slug}&bypass=1`)
  }
  const sendSmsWithPinOnLiczakOffer = (slug) => {
    mockApiCall('sendSmsWithPinOnLiczakOffer', slug);
    return  api.request(`/personal-form/send-pin/${slug}`)
  }

  const getPinValidationLiczakOffer = (slug, pin) => {
    mockApiCall('sendSmsWithPinOnLiczakOffer', slug);
    return  api.request(`/personal-form/check-pin/${slug}/${pin}`)
  }

  const postFormLiczakOffer = (slug, data) => {
    mockApiCall('postFormLiczakOffer');
    return api.request(`/personal-form/store/${slug}`, data, 'post')
  }

  const uploadFilesLiczakOffer = (slug, data) => {
    mockApiCall('uploadFilesLiczakOffer');
    return api.request(`/personal-form/upload-file/${slug}`, data, 'post')
  }

  const deleteFileLiczakOffer = (slug, data) => {
    mockApiCall('uploadFilesLiczakOffer');
    return api.request(`/personal-form/delete-file/${slug}`, data, 'post')
  }

  const checkIsLoggedOperator = (token, uuid) => {
    mockApiCall('checkIsLoggedOperator');
    return api.request(`/personal-form/auth/${token}/${uuid}`)
  }

  // -- moto --

  const getNews = () => {
    return api.request(`/moto-news?bypass=1`);
  }

  const getBannersForSlot = (slot) => {
    return api.request(`/slot-banners?slot=${slot}`);
  }
  const getBannersForSlotByBrand = (brand, slot) => {
    return api.request(`/v3/brand-slot-banners?brand=${brand}&slot=${slot}`);
  }

  const getBlogCategories = () => {
    return api.request(`/blog/categories?bypass=1`);
  }

  const getMotoHomepageData = () => {
    mockApiCall('motoHomePage');
    return api.request(`/blog/home-page?bypass=1`);
  }

  const getMotoCategoryPageData = (slug='wiadomosci', page='0') => {
    mockApiCall('motoCategoryPage');
    return api.request(`/blog/category-page?slug=${slug}&page=${page}&bypass=1`);
  }

  const getMotoSearchPageData = (query='premiery', page='0') => {
    mockApiCall('motoSearchPage');
    return api.request(`/blog/search?search=${query}&page=${page}&bypass=1`);
  }

  const getMotoArticleData = (slug) => {
    mockApiCall('motoArticlePost');
    return api.request(`/blog/article-page?slug=${slug}&bypass=1`)
  };


  const groupCommunicates = (results) => {
    const entries = {};
    results.forEach(entry => {
      const year = entry.date.substring(0, 4);
      if (!entries[year]) entries[year] = [];
      entries[year].push(entry);
    })

    Object.keys(entries).forEach(year => {
      const files = entries[year];
      sortResults(entries[year], 'date')
      entries[year].reverse();
    })

    return entries;
  }

  const sortResults = (result, property) => {
    const compare = (a, b) => {
      const carA = a[property]
      const carB = b[property];
      if (carA < carB) return -1;
      if (carA > carB) return 1;
      return 0;
    }
    result.sort(compare);
  }

  const mockApiCall = (name, ...params) => {
    console.log('☁️ BKND: ' + name, ...params)
  }

  const storageCall = (name) => {
    console.log('💿 STOR: ' + name)
  }

  const getFromStore = (key) => {
    const data = backendStore[key];
    if (data) storageCall(key);
    return data.value;
  }

  return {
    getSetup,
    getModels,
    getBrands,
    getBodies,
    getPurposes,
    getCar,
    getCars,
    getCarsBodyTypes,
    getCarsByRoute,
    getCommunicates,
    sendContactForm,
    sendDealerContactForm,
    getFromStore,
    getDownloadLinkForFile,
    getEquipmentDictionary,
    teamsNotify,
    getModelSeo,
    getBrandSeo,
    getLimitedOffer,
    getLanding,
    getActualLanding,
    getBanners,
    getBannerBrands,
    getDigitalOrder,
    checkDigitalOrderStatus,
    createDigitalOrder,
    digitalOrderStoreContact,
    getDigitalOrderSetup,
    leasingParamDigitalOrder,
    storeDigitalOrderData,
    storeShowOffer,
    getRedirectLanding,
    getModelBodyVariants,
    groupCarsIntoVariants,
    getModelBodyTypes,
    getBrandsET,
    getModelsET,
    getVersionsET,
    getDocument,
    getFooterLinks,
    getCompanyData,
    getBankList,
    initiatePsd2,
    initiatePsd2Result,
    getNews,
    getBannersForSlot,
    getBannersForSlotByBrand,
    search,
    getModelDescription,
    getModelDescriptionCards,
    getCollection,
    getCollections,
    getGearBoxes,
    getDrives,
    getCarLiczakOffer,
    sendSmsWithPinOnLiczakOffer,
    getPinValidationLiczakOffer,
    postFormLiczakOffer,
    uploadFilesLiczakOffer,
    deleteFileLiczakOffer,
    checkIsLoggedOperator,
    getBlogCategories,
    getMotoHomepageData,
    getMotoCategoryPageData,
    getMotoSearchPageData,
    getMotoArticleData
  }
}
