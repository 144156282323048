import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as collectionsJ80kKaZK7VMeta } from "~/pages/collections.vue?macro=true";
import { default as brandYWBVMYgUIyMeta } from "~/pages/brand.vue?macro=true";
import { default as modelDAtUec8XrxMeta } from "~/pages/model.vue?macro=true";
import { default as carseiOm1dIWzEMeta } from "~/pages/cars.vue?macro=true";
import { default as offerFNEdRB6VdlMeta } from "~/pages/offer.vue?macro=true";
import { default as search30UvnVpHlGMeta } from "~/pages/search.vue?macro=true";
import { default as decidegtb4pMDxYhMeta } from "~/pages/decide.vue?macro=true";
import { default as compare3ICY1PgsiRMeta } from "~/pages/compare.vue?macro=true";
import { default as documentNjN80S97uYMeta } from "~/pages/document.vue?macro=true";
import { default as forms7MnQbycpt7Meta } from "~/pages/transaction/forms.vue?macro=true";
import { default as back9VyIoytYySMeta } from "~/pages/transaction/back.vue?macro=true";
import { default as buildPdFzPm4Iz0Meta } from "~/pages/transaction/build.vue?macro=true";
import { default as portalsYkpDTcUG7mMeta } from "~/pages/marketing/portals.vue?macro=true";
import { default as congress6VRrQyadpAMeta } from "~/pages/marketing/congress.vue?macro=true";
import { default as about8V6CE3ZcejMeta } from "~/pages/static/about.vue?macro=true";
import { default as offerBTJ7gBcng5Meta } from "~/pages/static/offer.vue?macro=true";
import { default as offer_45consumer7PVf0ljpimMeta } from "~/pages/static/offer-consumer.vue?macro=true";
import { default as offer_45entrepreneur4vhFKLj8WsMeta } from "~/pages/static/offer-entrepreneur.vue?macro=true";
import { default as insurancesC9PC3QHFIdMeta } from "~/pages/static/insurances.vue?macro=true";
import { default as contactwlOmfdLwMcMeta } from "~/pages/static/contact.vue?macro=true";
import { default as informationeFYIR1waqEMeta } from "~/pages/static/information.vue?macro=true";
import { default as financing_45onlyUf1npGyuU3Meta } from "~/pages/static/financing-only.vue?macro=true";
import { default as liczak_45offercShfntq9UfMeta } from "~/pages/external/liczak-offer.vue?macro=true";
import { default as homexGEswlR6IkMeta } from "~/pages/moto/home.vue?macro=true";
import { default as categoryTZLxR7ZEhQMeta } from "~/pages/moto/category.vue?macro=true";
import { default as postyQVmhySaW4Meta } from "~/pages/moto/post.vue?macro=true";
import { default as searchnkyuuzGn16Meta } from "~/pages/moto/search.vue?macro=true";
import { default as catch_45allLzSIblWAOgMeta } from "~/pages/catch-all.vue?macro=true";
import { default as component_45stubH1kpuk0RbMMeta } from "/srv/portal/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubH1kpuk0RbM } from "/srv/portal/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexp9V2p5fxycMeta?.name ?? "index",
    path: indexp9V2p5fxycMeta?.path ?? "/",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: collectionsJ80kKaZK7VMeta?.name ?? "collections",
    path: collectionsJ80kKaZK7VMeta?.path ?? "/kolekcje/:slug",
    meta: collectionsJ80kKaZK7VMeta || {},
    alias: collectionsJ80kKaZK7VMeta?.alias || [],
    redirect: collectionsJ80kKaZK7VMeta?.redirect,
    component: () => import("~/pages/collections.vue").then(m => m.default || m)
  },
  {
    name: brandYWBVMYgUIyMeta?.name ?? "brand",
    path: brandYWBVMYgUIyMeta?.path ?? "/samochody/:brand",
    meta: brandYWBVMYgUIyMeta || {},
    alias: brandYWBVMYgUIyMeta?.alias || [],
    redirect: brandYWBVMYgUIyMeta?.redirect,
    component: () => import("~/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: modelDAtUec8XrxMeta?.name ?? "model",
    path: modelDAtUec8XrxMeta?.path ?? "/samochody/:brand/:model",
    meta: modelDAtUec8XrxMeta || {},
    alias: modelDAtUec8XrxMeta?.alias || [],
    redirect: modelDAtUec8XrxMeta?.redirect,
    component: () => import("~/pages/model.vue").then(m => m.default || m)
  },
  {
    name: carseiOm1dIWzEMeta?.name ?? "cars",
    path: carseiOm1dIWzEMeta?.path ?? "/wybierz/:brand/:model",
    meta: carseiOm1dIWzEMeta || {},
    alias: carseiOm1dIWzEMeta?.alias || [],
    redirect: carseiOm1dIWzEMeta?.redirect,
    component: () => import("~/pages/cars.vue").then(m => m.default || m)
  },
  {
    name: offerFNEdRB6VdlMeta?.name ?? "offer",
    path: offerFNEdRB6VdlMeta?.path ?? "/oferta/:id",
    meta: offerFNEdRB6VdlMeta || {},
    alias: offerFNEdRB6VdlMeta?.alias || [],
    redirect: offerFNEdRB6VdlMeta?.redirect,
    component: () => import("~/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: search30UvnVpHlGMeta?.name ?? "search",
    path: search30UvnVpHlGMeta?.path ?? "/wyszukaj/",
    meta: search30UvnVpHlGMeta || {},
    alias: search30UvnVpHlGMeta?.alias || [],
    redirect: search30UvnVpHlGMeta?.redirect,
    component: () => import("~/pages/search.vue").then(m => m.default || m)
  },
  {
    name: decidegtb4pMDxYhMeta?.name ?? "decide",
    path: decidegtb4pMDxYhMeta?.path ?? "/pobierz/:id",
    meta: decidegtb4pMDxYhMeta || {},
    alias: decidegtb4pMDxYhMeta?.alias || [],
    redirect: decidegtb4pMDxYhMeta?.redirect,
    component: () => import("~/pages/decide.vue").then(m => m.default || m)
  },
  {
    name: compare3ICY1PgsiRMeta?.name ?? "favorites",
    path: compare3ICY1PgsiRMeta?.path ?? "/ulubione/",
    meta: compare3ICY1PgsiRMeta || {},
    alias: compare3ICY1PgsiRMeta?.alias || [],
    redirect: compare3ICY1PgsiRMeta?.redirect,
    component: () => import("~/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: documentNjN80S97uYMeta?.name ?? "document",
    path: documentNjN80S97uYMeta?.path ?? "/informacje/:slug",
    meta: documentNjN80S97uYMeta || {},
    alias: documentNjN80S97uYMeta?.alias || [],
    redirect: documentNjN80S97uYMeta?.redirect,
    component: () => import("~/pages/document.vue").then(m => m.default || m)
  },
  {
    name: forms7MnQbycpt7Meta?.name ?? "transaction-form",
    path: forms7MnQbycpt7Meta?.path ?? "/zamow/:uuid",
    meta: forms7MnQbycpt7Meta || {},
    alias: forms7MnQbycpt7Meta?.alias || [],
    redirect: forms7MnQbycpt7Meta?.redirect,
    component: () => import("~/pages/transaction/forms.vue").then(m => m.default || m)
  },
  {
    name: back9VyIoytYySMeta?.name ?? "transaction-form-back",
    path: back9VyIoytYySMeta?.path ?? "/powrot/:uuid/:secret",
    meta: back9VyIoytYySMeta || {},
    alias: back9VyIoytYySMeta?.alias || [],
    redirect: back9VyIoytYySMeta?.redirect,
    component: () => import("~/pages/transaction/back.vue").then(m => m.default || m)
  },
  {
    name: buildPdFzPm4Iz0Meta?.name ?? "transaction-build",
    path: buildPdFzPm4Iz0Meta?.path ?? "/sfinansuj-online/:brandOrId/:model?/:body?",
    meta: buildPdFzPm4Iz0Meta || {},
    alias: buildPdFzPm4Iz0Meta?.alias || [],
    redirect: buildPdFzPm4Iz0Meta?.redirect,
    component: () => import("~/pages/transaction/build.vue").then(m => m.default || m)
  },
  {
    name: portalsYkpDTcUG7mMeta?.name ?? "marketing-portals",
    path: portalsYkpDTcUG7mMeta?.path ?? "/oferta/portale",
    meta: portalsYkpDTcUG7mMeta || {},
    alias: portalsYkpDTcUG7mMeta?.alias || [],
    redirect: portalsYkpDTcUG7mMeta?.redirect,
    component: () => import("~/pages/marketing/portals.vue").then(m => m.default || m)
  },
  {
    name: congress6VRrQyadpAMeta?.name ?? "for-dealer",
    path: congress6VRrQyadpAMeta?.path ?? "/dla-dealera",
    meta: congress6VRrQyadpAMeta || {},
    alias: congress6VRrQyadpAMeta?.alias || [],
    redirect: congress6VRrQyadpAMeta?.redirect,
    component: () => import("~/pages/marketing/congress.vue").then(m => m.default || m)
  },
  {
    name: about8V6CE3ZcejMeta?.name ?? "static-about",
    path: about8V6CE3ZcejMeta?.path ?? "/o-nas",
    meta: about8V6CE3ZcejMeta || {},
    alias: about8V6CE3ZcejMeta?.alias || [],
    redirect: about8V6CE3ZcejMeta?.redirect,
    component: () => import("~/pages/static/about.vue").then(m => m.default || m)
  },
  {
    name: offerBTJ7gBcng5Meta?.name ?? "static-offer",
    path: offerBTJ7gBcng5Meta?.path ?? "/oferta",
    meta: offerBTJ7gBcng5Meta || {},
    alias: offerBTJ7gBcng5Meta?.alias || [],
    redirect: offerBTJ7gBcng5Meta?.redirect,
    component: () => import("~/pages/static/offer.vue").then(m => m.default || m)
  },
  {
    name: offer_45consumer7PVf0ljpimMeta?.name ?? "static-offer-consumer",
    path: offer_45consumer7PVf0ljpimMeta?.path ?? "/oferta/dla-konsumenta",
    meta: offer_45consumer7PVf0ljpimMeta || {},
    alias: offer_45consumer7PVf0ljpimMeta?.alias || [],
    redirect: offer_45consumer7PVf0ljpimMeta?.redirect,
    component: () => import("~/pages/static/offer-consumer.vue").then(m => m.default || m)
  },
  {
    name: offer_45entrepreneur4vhFKLj8WsMeta?.name ?? "static-offer-entrepreneur",
    path: offer_45entrepreneur4vhFKLj8WsMeta?.path ?? "/oferta/dla-przedsiebiorcy",
    meta: offer_45entrepreneur4vhFKLj8WsMeta || {},
    alias: offer_45entrepreneur4vhFKLj8WsMeta?.alias || [],
    redirect: offer_45entrepreneur4vhFKLj8WsMeta?.redirect,
    component: () => import("~/pages/static/offer-entrepreneur.vue").then(m => m.default || m)
  },
  {
    name: insurancesC9PC3QHFIdMeta?.name ?? "static-insurances",
    path: insurancesC9PC3QHFIdMeta?.path ?? "/oferta/ubezpieczenia",
    meta: insurancesC9PC3QHFIdMeta || {},
    alias: insurancesC9PC3QHFIdMeta?.alias || [],
    redirect: insurancesC9PC3QHFIdMeta?.redirect,
    component: () => import("~/pages/static/insurances.vue").then(m => m.default || m)
  },
  {
    name: contactwlOmfdLwMcMeta?.name ?? "static-contact",
    path: contactwlOmfdLwMcMeta?.path ?? "/kontakt",
    meta: contactwlOmfdLwMcMeta || {},
    alias: contactwlOmfdLwMcMeta?.alias || [],
    redirect: contactwlOmfdLwMcMeta?.redirect,
    component: () => import("~/pages/static/contact.vue").then(m => m.default || m)
  },
  {
    name: informationeFYIR1waqEMeta?.name ?? "static-information",
    path: informationeFYIR1waqEMeta?.path ?? "/komunikaty",
    meta: informationeFYIR1waqEMeta || {},
    alias: informationeFYIR1waqEMeta?.alias || [],
    redirect: informationeFYIR1waqEMeta?.redirect,
    component: () => import("~/pages/static/information.vue").then(m => m.default || m)
  },
  {
    name: financing_45onlyUf1npGyuU3Meta?.name ?? "static-financing-only",
    path: financing_45onlyUf1npGyuU3Meta?.path ?? "/leasing-samochodu",
    meta: financing_45onlyUf1npGyuU3Meta || {},
    alias: financing_45onlyUf1npGyuU3Meta?.alias || [],
    redirect: financing_45onlyUf1npGyuU3Meta?.redirect,
    component: () => import("~/pages/static/financing-only.vue").then(m => m.default || m)
  },
  {
    name: liczak_45offercShfntq9UfMeta?.name ?? "external-offer",
    path: liczak_45offercShfntq9UfMeta?.path ?? "/o2/:uuid?",
    meta: liczak_45offercShfntq9UfMeta || {},
    alias: liczak_45offercShfntq9UfMeta?.alias || [],
    redirect: liczak_45offercShfntq9UfMeta?.redirect,
    component: () => import("~/pages/external/liczak-offer.vue").then(m => m.default || m)
  },
  {
    name: homexGEswlR6IkMeta?.name ?? "moto",
    path: homexGEswlR6IkMeta?.path ?? "/moto",
    meta: homexGEswlR6IkMeta || {},
    alias: homexGEswlR6IkMeta?.alias || [],
    redirect: homexGEswlR6IkMeta?.redirect,
    component: () => import("~/pages/moto/home.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-newest",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/najnowsze",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-news",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/wiadomosci",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-premiere",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/premiery",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-tests",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/testy",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-road",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/na-drodze",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-eauto",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/e-auto",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-advices",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/porady",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-photos",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/zdjecia",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-movies",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/filmy",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-clacson",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/clacson",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: categoryTZLxR7ZEhQMeta?.name ?? "blog-leasing",
    path: categoryTZLxR7ZEhQMeta?.path ?? "/moto/kategoria/o-leasingu",
    meta: categoryTZLxR7ZEhQMeta || {},
    alias: categoryTZLxR7ZEhQMeta?.alias || [],
    redirect: categoryTZLxR7ZEhQMeta?.redirect,
    component: () => import("~/pages/moto/category.vue").then(m => m.default || m)
  },
  {
    name: postyQVmhySaW4Meta?.name ?? "blog-post",
    path: postyQVmhySaW4Meta?.path ?? "/moto/artykul/:post?",
    meta: postyQVmhySaW4Meta || {},
    alias: postyQVmhySaW4Meta?.alias || [],
    redirect: postyQVmhySaW4Meta?.redirect,
    component: () => import("~/pages/moto/post.vue").then(m => m.default || m)
  },
  {
    name: searchnkyuuzGn16Meta?.name ?? "search-page",
    path: searchnkyuuzGn16Meta?.path ?? "/moto/wyszukaj:search?",
    meta: searchnkyuuzGn16Meta || {},
    alias: searchnkyuuzGn16Meta?.alias || [],
    redirect: searchnkyuuzGn16Meta?.redirect,
    component: () => import("~/pages/moto/search.vue").then(m => m.default || m)
  },
  {
    name: catch_45allLzSIblWAOgMeta?.name ?? "catch-all",
    path: catch_45allLzSIblWAOgMeta?.path ?? "/:slug(.*)*",
    meta: catch_45allLzSIblWAOgMeta || {},
    alias: catch_45allLzSIblWAOgMeta?.alias || [],
    redirect: catch_45allLzSIblWAOgMeta?.redirect,
    component: () => import("~/pages/catch-all.vue").then(m => m.default || m)
  },
  {
    name: component_45stubH1kpuk0RbMMeta?.name ?? undefined,
    path: component_45stubH1kpuk0RbMMeta?.path ?? "/kongres-dealerski",
    meta: component_45stubH1kpuk0RbMMeta || {},
    alias: component_45stubH1kpuk0RbMMeta?.alias || [],
    redirect: component_45stubH1kpuk0RbMMeta?.redirect,
    component: component_45stubH1kpuk0RbM
  },
  {
    name: component_45stubH1kpuk0RbMMeta?.name ?? undefined,
    path: component_45stubH1kpuk0RbMMeta?.path ?? "/podepnij-sie",
    meta: component_45stubH1kpuk0RbMMeta || {},
    alias: component_45stubH1kpuk0RbMMeta?.alias || [],
    redirect: component_45stubH1kpuk0RbMMeta?.redirect,
    component: component_45stubH1kpuk0RbM
  }
]